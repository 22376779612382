/* global ui */

(function ($) {
	$.widget('ipnp.headerFlyout', {

		options: {},

		_create() {
			const _this = this;

			ui.sub('showFlyoutOverlay', () => {
				_this.showFlyoutOverlay();
			});

			ui.sub('hideFlyoutOverlay', () => {
				_this.hideFlyoutOverlay();
			});

			this.element.on('click', () => {
				ui.pub('clickedFlyoutOverlay');
			});
		},

		/**
		 * Shows the flyout-background element
		 */
		showFlyoutOverlay() {
			this.element.stop(true, true).fadeIn();
		},

		/**
		 * Hides the flyout-background element
		 */
		hideFlyoutOverlay() {
			this.element.stop(true, true).fadeOut();
		}
	});
}(ui.$));
