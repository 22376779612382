/* global ui */

(function ($) {
	$.widget('ipnp.electionCalendar', {
		options: {
			dateFormat: 'dd.mm.yy',
			calendarReference: '',
			holidaysSource: '',
			startDate: '',
			endDate: '',
			placeholder: {
				placeholderId: {
					defaultText: '',
					amount: 0,
					unit: '',
					workdayOnly: false
				}
			}
		},

		_create() {
			ui.merge('ipnp.electionCalendar', this);

			// initialize the date picker
			this._initDatepicker();
			// enrich table with information for the mobile view
			this._enrichTableForMobile();
			// register events for the print buttons
			this._registerPrintEvents();

			this._updateTableStyle();

			this._onBreakpointChanged();
		},

		/**
		 * Handle breakpoint changed
		 */
		_onBreakpointChanged() {
			const _this = this;
			ui.sub('ui.breakpointChanged', () => {
				_this._updateTableStyle();
			});
		},

		/**
		 * Change table style
		 */
		_updateTableStyle() {
			const _this = this;
			if (ui.lib.getBreakpoint() === 'xs' || ui.lib.getBreakpoint() === 'sm') {
				_this.element.find('table').removeClass('table--alternate-bg');
			} else {
				_this.element.find('table').addClass('table--alternate-bg');
			}
		},
		/**
		 * Initialize the functionality of the date picker.
		 *
		 * @private
		 */
		_initDatepicker() {
			const _this = this;
			const $dateField = this.element.find('.datepicker');
			const datePickerId = $dateField.attr('id');
			$dateField.datepicker({
				dateFormat: _this.options.dateFormat,
				monthNamesShort: $.datepicker.regional.de.monthNames,
				beforeShow(input) {
					$(input).closest('.date-field').addClass('datepicker--is-open');
					// update selectable dates according to the referenced calendar
					const calendarReferenceId = _this.options.calendarReference;
					if (calendarReferenceId) {
						const $referenceDatepicker = $(`#${calendarReferenceId}`).find('.datepicker');
						const referencedDate = $referenceDatepicker.datepicker('getDate');
						if (referencedDate) {
							const minDate = new Date(referencedDate);
							minDate.setDate(minDate.getDate() + 7);
							$dateField.datepicker('option', 'minDate', minDate);
						} else {
							$dateField.datepicker('option', 'minDate', null);
						}
					}
				},

				onClose(dateText, inst) {
					inst.input.closest('.date-field').removeClass('datepicker--is-open');
				},

				onSelect(dateText, datefieldObject) {
					_this._updateCalendar(dateText, datefieldObject, datePickerId);
				}
			});

			// set minimum and/or maximum selectable date if configured
			const { startDate } = this.options;
			const { endDate } = this.options;
			if (startDate) {
				// hint: will be overwritten by a referenced calendar
				$dateField.datepicker('option', 'minDate', new Date(startDate));
			}
			if (endDate) {
				$dateField.datepicker('option', 'maxDate', new Date(endDate));
			}

			// store all holidays at js array
			this.options.holidays = [];

			if (_this.options.holidaysSource) {
				$.ajax({
					type: 'GET',
					url: _this.options.holidaysSource,
					async: true,
					success(data) {
						_this._collectDates(data);
					}
				});
			}
		},

		/**
		 * Collect all dates received from calendar REST API and stores it to a local array to check it after selecting an election date.
		 * @param data data received from REST API
		 * @private
		 */
		_collectDates(data) {
			let holidayDate;
			switch (data['@nodeType']) {
				case 'mgnl:folder':
					// iterate directories
					data['@nodes'].forEach((folder) => this._collectDates(data[folder]));
					break;
				case 'ipnp:calendar':
					// push date
					holidayDate = new Date(data.date);
					// ignore time
					holidayDate.setHours(0, 0, 0, 0);
					this.options.holidays.push(holidayDate);
					break;
				default:
					ui.log(`${this.widgetName} - Node Type ${data['@nodeType']} unknown.`);
			}
		},
		/**
		 * This method enriches the table data elements with information about their heading. Useful for mobile styling.
		 *
		 * @private
		 */
		_enrichTableForMobile() {
			const $calendarContent = this.element.find('.election-calendar__content');
			const $milestoneTable = $calendarContent.find('table').eq(0);
			if ($milestoneTable) {
				$milestoneTable.find('th').each(function (thIndex) {
					const headingText = $(this).text().trim();
					$milestoneTable.find('tr').slice(1).each(function () {
						$(this).children().eq(thIndex).attr('data-heading', headingText);
					});
				});
				this._cloneMilestonesForMobile($calendarContent, $milestoneTable);
			}
		},

		/**
		 * Clone the milestone table for the mobile representation.
		 *
		 * @param $calendarContent the election calendar content (the corresponding <div>-element)
		 * @param $milestoneTable the milestone table
		 * @private
		 */
		_cloneMilestonesForMobile($calendarContent, $milestoneTable) {
			if (ui.lib.getBreakpoint() === 'xs' || ui.lib.getBreakpoint() === 'sm') {
				const $pastMilestoneTable = $milestoneTable.clone();
				const $pastMilestonesContent = $calendarContent.find('.election-calendar__past-milestones').eq(0);
				$pastMilestonesContent.append($pastMilestoneTable);
			}
		},

		/**
		 * Update calendar content on date change.
		 *
		 * @param dateText current datepicker text
		 * @param datefieldObject current date field object
		 * @param datePickerId id of the current date picker
		 * @private
		 */
		_updateCalendar(dateText, datefieldObject, datePickerId) {
			const _this = this;
			if (this.options.placeholder) {
				const $calendarContent = this.element.find('.election-calendar__content');
				const currentDate = new Date();
				// ignore time
				currentDate.setHours(0, 0, 0, 0);
				$calendarContent.find('[data-calendar-placeholder]').each(function () {
					_this._replaceDatePlaceholder($(this), datefieldObject, datePickerId, currentDate);
				});
				// the first found milestone in the future is the upcoming milestone (present)
				$calendarContent.find('[data-milestone-timebox="future"]').eq(0).attr('data-milestone-timebox', 'present');

				// show past milestones in corresponding accordion if available (only on mobile devices)
				if (ui.lib.getBreakpoint() === 'xs' || ui.lib.getBreakpoint() === 'sm') {
					const $calendarContentMobile = _this.element.find('.election-calendar__content--mobile');
					if ($calendarContent.find('[data-milestone-timebox="past"]').length > 0) {
						$calendarContentMobile.show();
					} else {
						$calendarContentMobile.hide();
					}
				}
			}
		},

		/**
		 * Replace the date placeholder with the calculated date and set the timebox for each milestone.
		 *
		 * @param placeholderElement date placeholder element
		 * @param datefieldObject current date field object
		 * @param datePickerId id of the current date picker
		 * @param currentDate current date ('today')
		 * @private
		 */
		_replaceDatePlaceholder(placeholderElement, datefieldObject, datePickerId, currentDate) {
			const placeholderId = placeholderElement.attr('data-calendar-placeholder');
			const currentPlaceholder = this.options.placeholder[placeholderId];
			// allow subtraction of 0 days/month in order to show milestones on the selected date
			if (currentPlaceholder && currentPlaceholder.amount >= 0 && currentPlaceholder.unit) {
				const selectedDate = new Date(
					datefieldObject.selectedYear,
					datefieldObject.selectedMonth,
					datefieldObject.selectedDay
				);
				let placeHolderDate = selectedDate;
				switch (currentPlaceholder.unit) {
					case 'Days':
						placeHolderDate.setDate(selectedDate.getDate() - currentPlaceholder.amount);
						break;
					case 'Months':
						placeHolderDate.setMonth(selectedDate.getMonth() - currentPlaceholder.amount);
						break;
					default:
						ui.log(`${this.widgetName} - Unit ${currentPlaceholder.unit} unknown.`);
				}
				// check if only workdays are allowed and recalculate date
				if (currentPlaceholder.workdayOnly) {
					placeHolderDate = this._getWorkDay(placeHolderDate, this.options.holidays);
				}
				// replace the placeholder with the calculated date
				placeholderElement.text(placeHolderDate.toLocaleDateString(
					'de-DE',
					{
						weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'
					}
				));
				// check and set if a milestone is in the past, in the present or in the future
				const milestoneRow = placeholderElement.closest('tr');
				if (currentDate > placeHolderDate) {
					milestoneRow.attr('data-milestone-timebox', 'past');
				} else {
					milestoneRow.attr('data-milestone-timebox', 'future');
				}
			} else {
				ui.log(`${this.widgetName} - Cannot find placeholder with id ${placeholderId}`);
			}
		},

		/**
		 * Check if target date is a holiday or a day of the weekend. If so, set the date to the previous work day.
		 *
		 * @param targetDate selected date
		 * @param holidays list of configured holidays
		 * @returns {*} the calculated work day depending on the selection
		 * @private
		 */
		_getWorkDay(targetDate, holidays) {
			const _this = this;
			// check weekend
			const targetWeekday = targetDate.getDay();
			// sunday
			if (targetWeekday === 0) {
				ui.log(`${this.widgetName} - sunday at ${targetDate}`);
				targetDate.setDate(targetDate.getDate() - 2);
				return this._getWorkDay(targetDate, holidays);
			}
			// saturday
			if (targetWeekday === 6) {
				ui.log(`${this.widgetName} - saturday at ${targetDate}`);
				targetDate.setDate(targetDate.getDate() - 1);
				return this._getWorkDay(targetDate, holidays);
			}

			// check for holiday
			holidays.forEach((holidayDate) => {
				if (holidayDate.getTime() === targetDate.getTime()) {
					ui.log(`${this.widgetName} - holiday at ${targetDate}`);
					targetDate.setDate(targetDate.getDate() - 1);
					return _this._getWorkDay(targetDate, holidays);
				}
			});
			return targetDate;
		},

		/**
		 * Register the necessary events for the print dialog.
		 *
		 * @private
		 */
		_registerPrintEvents() {
			const _this = this;
			// register event listeners for (un-)setting the print configuration

			window.addEventListener('beforeprint', () => {
				// add class 'noprint' for every element that should not be visible in the print view
				const $mainContent = $('main');
				$mainContent.parent().siblings('div,header').addClass('election-calendar--noprint');
				$mainContent.siblings('div,header').addClass('election-calendar--noprint');
				$mainContent.children().each(function () {
					if (!_this.element.length) {
						$(this).addClass('election-calendar--noprint');
					}
				});
			});
			window.addEventListener('afterprint', () => {
				$('.election-calendar--noprint').removeClass('election-calendar--noprint');
			});
			// register each print button
			const $printButtons = this.element.find('.election-calendar__print .btn');
			$printButtons.each(function () {
				$(this).click(() => {
					window.print();
				});
			});
		}
	});
}(ui.$));
