/*
 * This is the main javascript file of all client DPAG specific scripts.
 * Component specific scripts where included by the dpag.bundle.js file of the client specific magnolia module.
 */

/* eslint-disable */

// Swiper JS
import Swiper from 'swiper/bundle';
import Masonry from 'masonry-layout';

// DPAG Components
import '../../components/header/header-flyout.overlay';
import '../../components/header/header';
import '../../components/header/header-navigation';
import '../../components/header/header-navigation.mobile';
import '../../components/stage/stage';
import '../../components/teaser/teaser';
import '../../components/subnavigation/subnavigation';
import '../../../../base/templates/components/election-calendar/election-calendar';
import '../../../../base/templates/components/isotope/isotope';
import '../../../../base/templates/components/image-navigation/image-navigation';
import '../../../../base/templates/components/social-media/social-media';

// initialize jquery widgets
import './ui.legacy/ui.index';

window.Swiper = Swiper;
window.Masonry = Masonry;
