(function (ui) {
	/* global ui */

	/**
	 * register widgets
	 */

	ui.register({
		widget: 'header'
	});

	ui.register({
		widget: 'headerFlyout',
		sel: '.flyout-background'
	});

	ui.register({
		widget: 'primarynavigation',
		sel: '.header__nav-container'
	});

	ui.register({
		widget: 'mobileNavigation',
		sel: '.header-mobile-nav-container'
	});

	ui.register({
		widget: 'imageNavigation',
		sel: '.image-navigation'
	});

	ui.register({
		widget: 'infotext',
		sel: '.infotext'
	});

	ui.register({
		widget: 'stage',
		sel: '.stage'
	});

	ui.register({
		widget: 'electionCalendar',
		sel: '.election-calendar'
	});

	ui.register({
		widget: 'isotope',
		sel: '.isotope'
	});

	ui.register({
		widget: 'socialmedia',
		sel: '.social-media'
	});

	ui.register({
		widget: 'subnavigation',
		sel: '.subnavigation'
	});

	ui.register({
		widget: 'teaser',
		sel: '.teaser'
	});

	ui.$(() => {
		ui.init();
	});
}(ui));
