/* global ui, Swiper */

(function ($) {
	$.widget('ipnp.imageNavigation', {
		_create() {
			if (!ui.lib.isEditMode()) {
				/* eslint-disable-next-line no-new */
				new Swiper(this.element.find('.image-navigation__swiper')[0], {
					direction: 'horizontal',
					// default views in xs viewport
					slidesPerView: 1,
					slidesPerGroup: 1,
					offsetPxAfter: 20,
					paginationClickable: true,
					speed: 500,
					centerInsufficientSlides: true,
					breakpoints: {
						// lg viewport
						[ui.lib.breakpointLimits.lg]: {
							slidesPerView: 5,
							slidesPerGroup: 5
						},
						// sm and md viewport
						[ui.lib.breakpointLimits.sm]: {
							slidesPerView: 3,
							slidesPerGroup: 3
						}
					},
					navigation: {
						nextEl: '.image-navigation .swiper-button-next',
						prevEl: '.image-navigation .swiper-button-prev'
					},

					pagination: {
						el: '.image-navigation .swiper-pagination',
						clickable: true
					},

					keyboard: {
						enabled: true
					}
				});
			}
		}
	});
}(ui.$));
