/* global ui */

(function ($) {
	$.widget('ipnp.teaser', {

		_create() {
			// add focus visible style to teaser tiles
			if ($(this.element)[0].classList.contains('teaser--tiles')) {
				$(this.element)[0].querySelector('.teaser__link').addEventListener('focus', (e) => {
					e.target.closest('.teaser').classList.add('focus-visible');
				});

				$(this.element)[0].querySelector('.teaser__link').addEventListener('blur', (e) => {
					e.target.closest('.teaser').classList.remove('focus-visible');
				});
			}
		}
	});
}(ui.$));
