/* global ui, Masonry */

(function ($) {
	$.widget('ipnp.isotope', {

		options: {
			itemSelector: '.isotope-item',
			itemExpandClass: 'isotope-item--expanded'
		},

		_create() {
			this._initIsotopeAfterImagesLoaded();
			this._openIsotopeItem();
			this._closeIsotopeItem();
			this._onBreakpointChanged();
		},

		/**
		 * returns width of isotope item for current viewport
		 */
		_getItemWidth() {
			let isotopeWidth = 0;

			if (ui.lib.getBreakpoint() === 'sm') {
				isotopeWidth = 208;
			} else if (ui.lib.getBreakpoint() === 'md') {
				isotopeWidth = 288;
			} else if (ui.lib.getBreakpoint() === 'lg') {
				isotopeWidth = 253;
			}
			return isotopeWidth;
		},

		/**
		 * Initialize isotope as Masonry after isotope item images loaded
		 */
		_initIsotopeAfterImagesLoaded() {
			const _this = this;
			const images = this.element[0].querySelectorAll(`${this.options.itemSelector} img`);

			// use Promise to call the function once after all images are loaded
			Promise.all(
				Array.from(images).filter((img) => !img.complete)
					.map((img) => new Promise((resolve) => {
						img.addEventListener('load', resolve);
						img.addEventListener('error', resolve);
					}))
			).then(
				() => {
					// init masonry for isotope after images finished loading
					_this._initMasonry();
				}
			);
		},

		/**
		 * Initialize isotope items as masonry
		 */
		_initMasonry() {
			if (!ui.lib.isEditMode()) {
				const _this = this;
				this.isotope = new Masonry(_this.element[0], {
					itemSelector: _this.options.itemSelector,
					columnWidth: _this._getItemWidth(),
					gutter: 32
				});
			}
		},

		/**
		 * Listener to open isotope item on click and enter
		 */
		_openIsotopeItem() {
			const _this = this;
			this.element.find(this.options.itemSelector).on('click keydown', function (e) {
				// isotope item is only open when the enter key is pressed
				if (e.type === 'keydown' && e.keyCode !== 13) {
					return;
				}

				_this.element.find(_this.options.itemSelector).removeClass(_this.options.itemExpandClass);
				$(this).addClass(_this.options.itemExpandClass);

				// refresh masonry
				_this.isotope.layout();
			});
		},

		/**
		 * Listener to close isotope item
		 */
		_closeIsotopeItem() {
			const _this = this;
			this.element.find('.isotope-item__close').on('click', function (e) {
				e.stopPropagation();
				$(this).parents(_this.options.itemSelector).removeClass(_this.options.itemExpandClass);

				// refresh masonry
				_this.isotope.layout();
			});
		},

		/**
		 * Reinitialize masonry with new with of isotope item after breakpoint changed
		 */
		_onBreakpointChanged() {
			const _this = this;
			ui.sub('ui.breakpointChanged', () => {
				_this.isotope.destroy();
				_this._initMasonry();
			});
		}
	});
}(ui.$));
