/* global ui */

(function ($) {
	$.widget('ipnp.primarynavigation', {

		options: {
			timer: '', animationDelay: '500'
		},

		_create() {
			ui.merge('ipnp.primarynavigation', this);

			const _this = this;
			const $navLinks = this.element.find('.header__nav-link');
			const $flyout = this.element.find('.nav-flyout');
			let navLinkInfoObjectOpen = {};

			$flyout.hide();

			this.element.on(
				'navLink.mouseenter navLink.click nav.mouseleave nav.clickoutside window.resizestart window.unload keydown.esc',
				(evt, navLinkInfoObject) => {
					let flyoutOpenAllowed = true;
					let animateFlyoutOpen = true;
					let animateFlyoutClose = false;

					if (Object.keys(navLinkInfoObjectOpen).length) {
						animateFlyoutOpen = false;
						const otherFlyoutIsOpened = evt.type === 'navLink' && navLinkInfoObjectOpen !== navLinkInfoObject;
						const closeFlyoutTriggered =
							// navContainer was left by mouse
							(evt.type === 'nav' && evt.namespace === 'mouseleave' && navLinkInfoObjectOpen.navLinkType === 'over')
							// click outside this nav
							|| (evt.type === 'nav' && evt.namespace === 'clickoutside')
							// esc key pressed
							|| (evt.type === 'keydown' && evt.namespace === 'esc')
							// nav was left by mouse
							|| (evt.type === 'window' && evt.namespace === 'resizestart')
							// page is left (e.g. click on link)
							|| (evt.type === 'window' && evt.namespace === 'unload')
							|| (
								// navLink was evt trigger
								evt.type === 'navLink'
								// by click
								&& evt.namespace === 'click'
								// navlink opens flyout on click
								&& navLinkInfoObjectOpen.navLinkType === 'click'
								// flyout of this triggered link is open
								&& navLinkInfoObjectOpen === navLinkInfoObject
							)
							|| otherFlyoutIsOpened;
						if (closeFlyoutTriggered) {
							// check if navLink was evt trigger and another flyout is open
							if (!otherFlyoutIsOpened) {
								// prevent reopening of flyout
								flyoutOpenAllowed = false;
								animateFlyoutClose = true;
							}

							const flyoutCloseObject = {};
							flyoutCloseObject.navLinkInfoObjectOpen = navLinkInfoObjectOpen;
							flyoutCloseObject.options = {
								animate: animateFlyoutClose
							};

							ui.pub('header.closeFlyout', { flyoutCloseObject });
							ui.pub('header.hideTriangle');
							navLinkInfoObjectOpen = {};
						}
					}
					if (evt.type === 'navLink' && navLinkInfoObject.hasFlyout
						&& flyoutOpenAllowed && !Object.keys(navLinkInfoObjectOpen).length
						&& (
							// triggered link opens flyout on hover and event was mouseenter ... no break ... move on
							(navLinkInfoObject.navLinkType === 'over' && evt.namespace === 'mouseenter')
							// triggered link opens flyout on mouseenter and event was click ... no break ... move on
							|| (navLinkInfoObject.navLinkType === 'over' && evt.namespace === 'click')
							// triggered link opens flyout on click and event was click
							|| (navLinkInfoObject.navLinkType === 'click' && evt.namespace === 'click'))
					) {
						navLinkInfoObjectOpen = navLinkInfoObject;

						const flyoutOpenObject = {};
						flyoutOpenObject.navLinkInfoObject = navLinkInfoObject;
						flyoutOpenObject.options = {
							animate: animateFlyoutOpen
						};

						ui.pub('header.openFlyout', { flyoutOpenObject });

						const triangleInformation = {
							offsetWidth: evt.target.offsetWidth,
							offsetLeft: evt.target.getBoundingClientRect().left,
							insideIconNav: evt.target.closest('.icon-nav') !== null
						};

						ui.pub('header.displayTriangle', { triangleInformation });
					}
				}
			);

			this.element.on('mouseleave', () => {
				clearTimeout(_this.options.timer);
				_this.options.timer = setTimeout(() => {
					_this.element.triggerHandler('nav.mouseleave');
				}, _this.options.animationDelay);
			});

			ui.sub('ui.windowresizestart', () => {
				_this.element.triggerHandler('window.resizestart');
			});
			// esc key down
			ui.sub('esc', () => {
				_this.element.triggerHandler('keydown.esc');
			});

			$(window).on('unload', () => {
				_this.element.triggerHandler('window.unload');
			});

			$('html').on('click', (evt) => {
				if (!$.contains(_this.element[0], evt.target)) {
					_this.element.triggerHandler('nav.clickoutside');
				}
			});

			this.processNavLinks($navLinks);
		},

		processNavLinks($navLinks) {
			const _that = this;
			$navLinks.each((index, navLink) => {
				const $navLink = $(navLink);
				const navLinkInfoObject = {};

				navLinkInfoObject.$navLink = $navLink;

				// get link type
				if ($navLink.is('.header__nav-link--mouse-over')) {
					navLinkInfoObject.navLinkType = 'over';
				} else if ($navLink.is('.header__nav-link--open')) {
					navLinkInfoObject.navLinkType = 'open';
				} else if ($navLink.is('.header__nav-link--mouse-click')) {
					clearTimeout(_that.options.timer);
					navLinkInfoObject.navLinkType = 'click';
				}

				// check for flyout
				navLinkInfoObject.hasFlyout = false;
				const $flyout = $navLink.parent().children('.nav-flyout');
				if ($flyout.length) {
					// flyout found
					navLinkInfoObject.hasFlyout = true;
					navLinkInfoObject.$flyout = $flyout;
				}

				$navLink.on('mouseenter', function () {
					const _this = this;
					clearTimeout(_that.options.timer);
					_that.options.timer = setTimeout(() => {
						$(_this).trigger('navLink.mouseenter', navLinkInfoObject);
					}, _that.options.animationDelay);
				});

				$navLink.on('mouseleave', function () {
					clearTimeout(_that.options.timer);
					$(this).trigger('navLink.mouseleave', navLinkInfoObject);
				});

				$navLink.on('click keydown', function (evt) {
					if (evt.key) {
						if (evt.key === 'Enter') {
							if ($(this).parent().hasClass('active')) {
								ui.pub('esc');
							}
						} else {
							return;
						}
					}

					$(this).trigger('navLink.click', navLinkInfoObject);
					if (navLinkInfoObject.navLinkType === 'click') {
						evt.stopPropagation();
						evt.preventDefault();
					}
					if ($('body').hasClass('ios')) {
						$flyout.find('.searchslot__field').focus();
					}
				});
			});
		}
	});
}(ui.$));
