/* global ui */

(function ($) {
	$.widget('ipnp.mobileNavigation', {

		_create() {
			const _this = this;
			const $navLinks = this.element.find('.header__nav-link');
			let navLinkInfoObjectOpen = {};

			this.element.find('.nav-mobile__submenu').each((index, navMobileSubmenu) => {
				const $navMobileSubmenu = $(navMobileSubmenu);
				const conf = {
					levelCounter: 0,
					flyout: $navMobileSubmenu.closest('.nav-flyout')
				};
				$.ipnp.mobileNavigation().handleLevelClick($navMobileSubmenu, conf);
				ui.sub('flyoutshown', () => {
					$.ipnp.mobileNavigation().handleInitialHeight($navMobileSubmenu);
				});
				ui.sub('resetFlyouts ui.windowresizestart', () => {
					$.ipnp.mobileNavigation().reinitHeaderMobileNavigation($navMobileSubmenu, conf);
				});
			});

			this.element.on('navLink.click window.resizestart nav.clickoutside', (evt, navLinkInfoObject) => {
				let flyoutOpenAllowed = true;
				const animateFlyoutOpen = true;
				const animateFlyoutClose = true;
				const otherFlyoutIsOpened = evt.type === 'navLink' && navLinkInfoObjectOpen !== navLinkInfoObject;
				const closeFlyoutTriggered =
					// click outside this nav ... no break, move on
					(evt.type === 'nav' && evt.namespace === 'clickoutside')
					// nav was left by mouse ... no break, move on
					|| (evt.type === 'window' && evt.namespace === 'resizestart')
					|| (
						// navLink was evt trigger
						evt.type === 'navLink'
						&& evt.namespace === 'click'
						// navlink opens flyout on click
						&& navLinkInfoObjectOpen.navLinkType === 'click'
						&& navLinkInfoObjectOpen === navLinkInfoObject)
					|| otherFlyoutIsOpened;
				if (Object.keys(navLinkInfoObjectOpen).length && closeFlyoutTriggered) {
					if (!otherFlyoutIsOpened) {
						// another flyout is open -> prevent reopening of flyout
						flyoutOpenAllowed = false;
					}
					$.ipnp.header().flyoutClose(navLinkInfoObjectOpen, { animate: animateFlyoutClose });
					navLinkInfoObjectOpen = {};
				}
				if (evt.type === 'navLink'
					&& navLinkInfoObject.hasFlyout && flyoutOpenAllowed && !Object.keys(navLinkInfoObjectOpen).length
					&& navLinkInfoObject.navLinkType === 'click' && evt.namespace === 'click') {
					// triggered link opens flyout on click and event was click
					navLinkInfoObjectOpen = navLinkInfoObject;
					$.ipnp.header().flyoutOpen(navLinkInfoObject, { animate: animateFlyoutOpen });
				}
			});

			ui.sub('ui.windowresizestart', () => {
				_this.element.triggerHandler('window.resizestart');
			});

			$('html').on('click', (evt) => {
				if (!$.contains(_this.element[0], evt.target)) {
					_this.element.triggerHandler('nav.clickoutside');
				}
			});

			$.ipnp.primarynavigation().processNavLinks($navLinks);
		},

		/**
		 * handles the level click to increase or decrease the level which should be shown depending on the element which is clicked
		 */
		handleLevelClick($navMobileSubmenu, conf) {
			let _this = this;
			const $subMenuTrigger = $navMobileSubmenu.find('.has-submenu');
			const $levelHeader = $navMobileSubmenu.find('.level-header');
			// show next child level
			$subMenuTrigger.find('> a').on('click', function (event) {
				event.stopPropagation();
				$.ipnp.mobileNavigation().showClosestLevel($navMobileSubmenu, $(this).parent('li'));
				conf.levelCounter += 1;
				$.ipnp.mobileNavigation().setLevelDepth($navMobileSubmenu, true, conf);
				$.ipnp.mobileNavigation().setFlyoutHeight($navMobileSubmenu, $(this).parent('li').find('ul.active'));
			});

			// show parent level/previous
			$levelHeader.find('> a').on('click', function (event) {
				event.stopPropagation();
				const $clickedElement = $(this);
				conf.levelCounter -= 1;
				$.ipnp.mobileNavigation().setLevelDepth($navMobileSubmenu, false, conf);
				setTimeout(() => {
					$.ipnp.mobileNavigation().hideClosestLevel($clickedElement, $navMobileSubmenu);
				}, 0.35);
			});
			// return to main level, when closing the hamburger flyout

			const container = $navMobileSubmenu.closest('.header-mobile-nav-container')[0];
			$(container).on('navLink.click nav.clickoutside keydown.esc', (event) => {
				if ($(event.target).hasClass('.header__nav-link')) {
					event.stopPropagation();
				}
				_this = $navMobileSubmenu.closest('.header__subnav').find('.header__nav-link');
				if (_this.closest('.header__subnav').hasClass('active')) {
					while (conf.levelCounter === 1 || conf.levelCounter === 2) {
						conf.levelCounter -= 1;
						$.ipnp.mobileNavigation().setLevelDepth($navMobileSubmenu, false, conf);
						setTimeout(() => {
							$.ipnp.mobileNavigation().hideClosestLevel(_this, $navMobileSubmenu);
						}, 0.35);
					}

					if (conf.levelCounter === 0) {
						$.ipnp.mobileNavigation().handleInitialHeight($navMobileSubmenu);
					}
				}
			});
			// iOS browser back cache fix
			window.onpageshow = function (event) {
				if (event.persisted) {
					window.location.reload();
				}
			};
		},

		/**
		 * show the closest level of an clicked element
		 * @param  {[Object]} $levelElement the clicked element where the closest level is searched from
		 * @param {[Object]} $navMobileSubmenu the submenu
		 */
		showClosestLevel($navMobileSubmenu, $levelElement) {
			const $listContainer = $levelElement.find('> ul');
			$listContainer.addClass('active');
			$listContainer.css('overflow', 'inherit');
			$.ipnp.mobileNavigation().setFlyoutHeight($navMobileSubmenu, $listContainer);
		},

		/**
		 * Sets the hight of the flyout depending on the height of the given active navigation level
		 * @param  {[Object]} $listContainer The active navigation listElement
		 * @param  {[Object]} $navMobileSubmenu The submenu
		 */
		setFlyoutHeight($navMobileSubmenu, $listContainer) {
			$listContainer.css('max-height', 'none');
			const listHeight = $listContainer.outerHeight();
			$listContainer.trigger('setFlyoutHeight', listHeight + 24);
			$navMobileSubmenu.closest('.nav-flyout__wrapper').height(listHeight);
		},

		/**
		 * set the level depth depending on the active navigation listElement to animate the slide (left) with css
		 * @param  {[Boolean]} increase if true the level depth will be increased by 1 if false it will be decreased by 1
		 * @param  {[Object]} $navMobileSubmenu The submenu
		 * @param  {[Object]} conf The flyout configuration
		 */
		setLevelDepth($navMobileSubmenu, increase, conf) {
			if (increase) {
				$navMobileSubmenu.addClass(`nav-level-${conf.levelCounter}`);
				$navMobileSubmenu.removeClass(`nav-level-${conf.levelCounter - 1}`);
			} else {
				$navMobileSubmenu.addClass(`nav-level-${conf.levelCounter}`);
				$navMobileSubmenu.removeClass(`nav-level-${conf.levelCounter + 1}`);
			}
		},

		/**
		 * hide the closest level of an clicked element
		 * @param  {[Object]} $levelElement the clicked element where the closest active level is searched from
		 * @param  {[Object]} $navMobileSubmenu The submenu
		 */
		hideClosestLevel($levelElement, $navMobileSubmenu) {
			let $listContainer = $levelElement.closest('ul.active');
			let $newParentHeightContainer;
			$listContainer.removeClass('active');
			$newParentHeightContainer = $levelElement.closest('ul.active');

			if ($newParentHeightContainer.length === 0) {
				$newParentHeightContainer = $navMobileSubmenu;
				$listContainer = $navMobileSubmenu;
			}

			$.ipnp.mobileNavigation().setFlyoutHeight($navMobileSubmenu, $newParentHeightContainer);
		},

		/**
		 * handle the initial height of the first entries which are shown
		 */
		handleInitialHeight($navMobileSubmenu) {
			if ($navMobileSubmenu.hasClass('nav-level-0')) {
				$.ipnp.mobileNavigation().setFlyoutHeight($navMobileSubmenu, $navMobileSubmenu);
			}
		},

		/**
		 * resets the level counter to 0 so the active level is level 0
		 */
		resetNavLevelClass($navMobileSubmenu, conf) {
			$navMobileSubmenu.removeClass(`nav-level-${conf.levelCounter}`);
			conf.levelCounter = 0;
			$.ipnp.mobileNavigation().options.levelCounter = 0;
			$navMobileSubmenu.addClass(`nav-level-${conf.levelCounter}`);
		},

		/**
		 * reinitialisation of the mobileNavigation Flyout needed for resize/recalculation
		 * removes active class from every child ul
		 */
		reinitHeaderMobileNavigation($navMobileSubmenu, conf) {
			$.ipnp.mobileNavigation().resetNavLevelClass($navMobileSubmenu, conf);
			$navMobileSubmenu.find('ul.active').each(function () {
				$(this).removeClass('active');
			});
		}
	});
}(ui.$));
