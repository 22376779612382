/* global ui */

(function ($) {
	$.widget('ipnp.socialmedia', {

		_create() {
			this._openItemUrl();
		},

		/**
		 * Builds social media item url and trigger this as new window popup
		 */
		_openItemUrl() {
			let title = $('meta[property=\'og:title\']').attr('content');
			let url = $('meta[property=\'og:url\']').attr('content');

			if (typeof title === 'undefined') {
				title = $('title').text();
			}
			if (typeof url === 'undefined') {
				url = new URL(window.location).href;
			}

			this.element.find('.social-media__link[data-target="whatsapp"]')
				.attr('href', `WhatsApp://send?text=${encodeURIComponent(title)} ${encodeURIComponent(url)}`);

			this.element.find('.social-media__link:not([data-target="whatsapp"])').click(function (e) {
				e.preventDefault();
				let href = $(this).attr('href');
				const origin = $(e.target).closest('.social-media__link').data('target');

				switch (origin) {
					case 'facebook':
						href += `?u=${encodeURIComponent(url)}`;
						break;
					case 'linkedin':
						href += `?url=${encodeURIComponent(url)}&title=${encodeURIComponent(title)}&mini=true`;
						break;
					default:
					// Should never be reached! (Added 'default' case to fix SonarQube report.)
				}

				window.open(href, $(this).text(), 'width=500, height=400 ,resizable=yes').focus();
			});
		}
	});
}(ui.$));
