/* global ui, Swiper */

(function ($) {
	$.widget('ipnp.stage', {

		options: {
			animationDelay: '10000'
		},

		_create() {
			ui.merge('ipnp.stage', this);
			const _this = this;

			if (!ui.lib.isEditMode()) {
				const countSlides = this.element.find('.swiper-slide').length;
				const swiper = new Swiper(this.element.find('.stage__swiper')[0], {
					// optional parameters
					direction: 'horizontal',
					loop: countSlides > 1,
					speed: 1000,

					autoplay: {
						delay: this.options.animationDelay,
						disableOnInteraction: true
					},

					navigation: {
						nextEl: '.stage .swiper-button-next',
						prevEl: '.stage .swiper-button-prev'
					},

					pagination: {
						el: '.stage .swiper-pagination',
						clickable: true
					},

					keyboard: {
						enabled: true
					}
				});

				swiper.on('slideChangeTransitionEnd', () => {
					_this.element.find('.swiper-slide-active .btn').removeAttr('tabindex');
					_this.element.find('.swiper-slide:not(.swiper-slide-active) .btn').attr('tabindex', '-1');
				});
			}
		}
	});
}(ui.$));
