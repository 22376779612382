/* global ui */

(function ($) {
	$.widget('ipnp.header', {
		options: {
			flyoutDuration: '300',
			flyoutInterval: null
		},

		_create() {
			const _this = this;

			ui.sub('header.closeFlyout', (e, data) => {
				if (data.flyoutCloseObject.navLinkInfoObjectOpen && data.flyoutCloseObject.options) {
					_this.flyoutClose(data.flyoutCloseObject.navLinkInfoObjectOpen, data.flyoutCloseObject.options);
				}
			});

			ui.sub('header.openFlyout', (e, data) => {
				if (data.flyoutOpenObject.navLinkInfoObject && data.flyoutOpenObject.options) {
					_this.flyoutOpen(data.flyoutOpenObject.navLinkInfoObject, data.flyoutOpenObject.options);
				}
			});

			ui.sub('header.hideTriangle', () => {
				_this.hideTriangle();
			});

			ui.sub('header.displayTriangle', (e, data) => {
				if (data.triangleInformation) {
					_this.displayTriangle(data.triangleInformation);
				}
			});
		},

		flyoutOpen(navLinkInfoObject, options) {
			// trigger waiting flyout.closed complete handlers of all flyouts
			$('.nav-flyout').each(function () {
				$(this).triggerHandler('flyout.closed');
			});

			const $flyout = $(navLinkInfoObject.$flyout);
			const $navLink = $(navLinkInfoObject.$navLink);

			if ($navLink.hasClass('animated-burger-icon')) {
				$navLink.addClass('animate');
			}

			this.rotateChevron($navLink, true);

			// force repaint (bugfix for empty search flyout on ios)
			$flyout[0].style.display = 'none';
			$flyout[0].style.display = '';

			// calculate flyout height and set duration of flyout opening animation based on flyout height
			let flyoutHeight = 0;
			$flyout.children().each(function () {
				flyoutHeight += $(this).outerHeight();
			});
			let animationDuration = 0;
			if (options.animate === true) {
				animationDuration = this.options.flyoutDuration;
			}

			// same height columns
			$flyout.find('.nav-flyout__row').each(function () {
				const columns = $(this).find('.nav-flyout__column');
				// cache the highest
				let highestBox = 0;
				// Select and loop the elements you want to equalise
				columns.each(function () {
					// If this box is higher than the cached highest then store it
					if ($(this).height() > highestBox) {
						highestBox = $(this).height();
					}
				});
				// Set the height of all those children to whichever was highest
				columns.css('height', highestBox);
			});

			// calculate flyout width and x-axis-offset
			let flyoutWidth = 0;
			let linkOffset = 0;
			if ($flyout.is('.fullsize-none')) {
				const $navFlyoutColumns = $flyout.find('.nav-flyout__column');
				$navFlyoutColumns.each(function () {
					flyoutWidth += $(this).outerWidth() + 1;
				});
			} else if ($flyout.is('.lang')) {
				// Check if desktop or mobile for proper positioning
				flyoutWidth = 60;
				if ($($flyout.closest('.header-navigation')).is('.header-navigation--mobile')) {
					$flyout.css({ left: '-3px' });
				}
			} else {
				flyoutWidth = $(window).width();
				linkOffset = $navLink.parent().offset();
			}

			// set css values
			$flyout.css({
				left: `-${linkOffset.left}px`,
				width: `${flyoutWidth}px`,
				transition: `max-height ${animationDuration}ms ease-in-out`,
				'max-height': `${flyoutHeight}px`
			});

			// DPAG-945
			if (navLinkInfoObject.navLinkType === 'click'
				&& $navLink.is('.header__nav-link--open-search, .header__nav-link--open-search-mobile')
				&& $flyout.find('.searchslot__field').length
			) {
				$flyout.one('flyout.opened', () => {
					$flyout.find('.searchslot__field').focus();
				});
			}

			window.setTimeout(() => {
				$flyout.triggerHandler('flyout.opened');
			}, animationDuration);

			$flyout.one('flyout.opened', () => {
				// mark parent .header__subnav as active
				$navLink.parent().addClass('active').attr('aria-expanded', 'true');
				$navLink.attr('aria-expanded', 'true');
				$navLink.next().attr('aria-expanded', 'true');

				// set focus to active main navigation link after last link in flyout focused out
				$navLink.parent().find('.nav-flyout a').last().focusout(() => {
					$navLink.focus();
				});
			});

			// handler for resizing of burger navigation content (dp.headermobilenavigation.setFlyoutHeight)
			// resize flyout height if needed
			$flyout.on('setFlyoutHeight', function (evt, newHeight) {
				$(this).css({
					'transition-duration': '0.25s',
					'max-height': `${newHeight}px`
				});
			});

			// trigger flyoutshown for setting initial height of burger navigation content
			// dp.headermobilenavigation.handleInitialHeight is started by subscription
			ui.pub('flyoutshown');

			// mark closest header-navigation as open
			$flyout.closest('.header-navigation').addClass('open');

			// clear timeout for fadein/fadeout of dark flyout background (if running)
			// and start new timeout for fadein of dark flyout background
			window.clearTimeout(this.options.flyoutInterval);
			this.options.flyoutInterval = window.setTimeout(() => {
				ui.pub('showFlyoutOverlay');
			}, 50);

			// track flyout open
			this.doTrack({
				action: 'flyout.open',
				trigger: navLinkInfoObject.$navLink
			});
		},

		rotateChevron(el, rotate) {
			const $el = $(el);
			if ($el.hasClass('header__nav-link')) {
				if (rotate) {
					$el.addClass('rotate');
				} else {
					$el.removeClass('rotate');
				}
			}
		},

		displayTriangle(offsets) {
			window.clearTimeout(window.triangleHideTimeout);
			const triangle = $('.header__nav-triangle');
			const marginRight = offsets.insideIconNav ? 16.5 : 35.5;
			const pos = `${offsets.offsetLeft + (offsets.offsetWidth / 2) - marginRight}px`;
			if (triangle.hasClass('d-none')) {
				triangle.removeClass('d-none');
				triangle.css('left', pos);
			}
			if (triangle) {
				triangle.animate({
					left: pos
				}, 0);
			}
		},

		hideTriangle() {
			window.triangleHideTimeout = window.setTimeout(() => {
				$('.header__nav-triangle').addClass('d-none');
			}, 50);
		},

		flyoutClose(navLinkInfoObject, options) {
			const _this = this;

			// trigger waiting flyout.opened complete handlers of all flyouts
			$('.nav-flyout').each(function () {
				$(this).triggerHandler('flyout.opened');
			});

			const $flyout = $(navLinkInfoObject.$flyout);
			const $navLink = $(navLinkInfoObject.$navLink);

			if ($navLink.hasClass('animated-burger-icon')) {
				$navLink.removeClass('animate');
			}

			$navLink.parent().removeClass('active').attr('aria-expanded', 'false');
			$navLink.attr('aria-expanded', 'false');
			$navLink.next().attr('aria-expanded', 'false');
			this.rotateChevron($navLink, false);

			// remove active and open classes on end of flyout closing
			// (triggered once via timeout or via opening of another flyout)
			$flyout.one('flyout.closed', () => {
				$flyout.closest('.header-navigation').removeClass('open');
				// hide keyboard on ios (if textinput inside flyout is focused, set focus to other element is not enough)
				if ($('body').hasClass('ios')) {
					document.activeElement.blur();
					$navLink.focus();
				}
			});

			// remove listener for resizing of burger navigation content (dp.headermobilenavigation.setFlyoutHeight)
			$flyout.off('setFlyoutHeight');

			// calculate and set duration of flyout close animation
			let animationDuration = 0;
			if (options.animate === true) {
				animationDuration = _this.options.flyoutDuration;
			}

			$flyout.css({
				'transition-duration': `${animationDuration}ms`,
				'max-height': '0'
			});

			$flyout.hide();

			window.setTimeout(() => {
				$flyout.triggerHandler('flyout.closed');
			}, animationDuration * 1000);

			// trigger reset of burger navigation content (dp.headermobilenavigation.reinitHeaderMobileNavigation)
			ui.pub('resetFlyouts');

			// clear timeout for fadein/fadeout of dark flyout background (if running)
			// and start new timeout for fadeout of dark flyout background
			window.clearTimeout(this.options.flyoutInterval);
			this.options.flyoutInterval = window.setTimeout(() => {
				ui.pub('hideFlyoutOverlay');
				_this.hideTriangle();
			}, 50);

			// track flyout close
			this.doTrack({
				action: 'flyout.close',
				trigger: navLinkInfoObject.$navLink
			});
		},

		doTrack(data) {
			// create empty tracking object
			const trackingObject = {};

			// set tracking action
			trackingObject.action = '';
			if (typeof data.action !== 'undefined') {
				trackingObject.action = data.action;
			}

			// set triggerlink for flyout and gathering additional informations (classes and linktext)
			trackingObject.trigger = $();
			if (typeof data.trigger !== 'undefined') {
				trackingObject.trigger = $(data.trigger);
				if (trackingObject.trigger.length) {
					trackingObject.triggerClass = trackingObject.trigger.attr('class');
					trackingObject.triggerText = trackingObject.trigger.text();
				}
			}

			// publish tracking data
			ui.pub('tracking.navigation', trackingObject);
		}
	});
}(ui.$));
