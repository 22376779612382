/* global ui */

(function ($) {
	$.widget('ipnp.subnavigation', {
		options: {
			classLinkItemActive: 'subnavigation__item--active'
		},

		_create() {
			// global variable of the scrollable linklist
			this.subnavLinklist = this.element[0].querySelector('.subnavigation__list');

			// global variable to change between mobile and desktop variant
			this.flyout = '';

			// boolean to scroll only if startDragging is called
			this.mouseDown = false;

			// x-pos of the page on click
			this.startX = 0;

			// scrollable pixel in left direction
			this.scrollLeft = 0;

			this._onBreakpointChanged();
			this._setItemPosition();
			this._slider();
			this._initFlyout();
		},

		_slider() {
			// EventListener for Desktop
			this.subnavLinklist.addEventListener('mousemove', (e) => this._moving(e), false);
			this.subnavLinklist.addEventListener('mousedown', (e) => this._startDragging(e), false);
			this.subnavLinklist.addEventListener('mouseup', (e) => this._stopDragging(e), false);
			this.subnavLinklist.addEventListener('mouseleave', (e) => this._stopDragging(e), false);

			// EventListener for Mobile
			this.subnavLinklist.addEventListener('touchmove', (e) => this._moving(e), false);
			this.subnavLinklist.addEventListener('touchstart', (e) => this._startDragging(e), false);
			this.subnavLinklist.addEventListener('touchend', (e) => this._stopDragging(e), false);
			this.subnavLinklist.addEventListener('touchcancel', (e) => this._stopDragging(e), false);
		},

		_initFlyout() {
			// get all link items which have a flyout
			const $flyoutLinkList = $('.subnavigation__item--flyout');

			// for each item, get their id and sub-id. For example: id: subnavigation__list--shop sub-id: shop
			for (let i = 0; i < $flyoutLinkList.length; i += 1) {
				const id = $flyoutLinkList[i].getAttribute('id');
				const subId = id.substring(21);
				this._handleFlyout(id, subId);
			}
		},

		_handleFlyout(id, subId) {
			const _this = this;

			// get clicked link item through id
			const $link = $(`#${id}`);

			// dim area
			const $flyoutBackground = $('.flyout-background');

			// handle touch/click and show/hide flyout
			$link.on({
				'touchmove click': function () {
					// if same button is clicked again dont hide all
					if (!($link.hasClass(_this.options.classLinkItemActive))) {
						_this._hideFlyouts();
					}

					// show/hide flyout based on global variable _this.flyout value and subId to find the correct flyout
					$(_this.flyout + subId).slideToggle();

					// dim background of the whole page
					$flyoutBackground.slideToggle('fast');

					// toggle line below list item
					$link.toggleClass(_this.options.classLinkItemActive);
				}
			});

			// if clicked outside of the subnav container close flyout element
			$(document).on({
				'touchstart click': function (e) {
					if (!_this.element.is(e.target) && _this.element.has(e.target).length === 0) {
						_this._hideFlyouts();
					}
				}
			});
		},

		_hideFlyouts() {
			const _this = this;

			// dim area
			const $flyoutBackground = $('.flyout-background');

			// list of all link items which have a flyout
			const $linkList = $('.subnavigation__item--flyout');

			// list of all mobile and desktop flyouts
			const $flyoutList = $('.subnavigation-flyout__mobile, .subnavigation-flyout__desktop');

			// hide dim area
			$flyoutBackground.hide();

			// make sure that no list item has the active class anymore
			$linkList.each(function () {
				$(this).removeClass(_this.options.classLinkItemActive);
			});

			// hide all flyouts
			$flyoutList.each(function () {
				$(this).hide();
			});
		},

		/**
		 * Check width of the navigation items and positions them accordingly.
		 * If there are too many navigation items, the items are positioned on a new line.
		 */
		_setItemPosition() {
			this.element.find('.subnavigation__caption').css('width', 'auto');
			this.element[0].classList.remove('subnavigation--top');

			// returns 0 as width if element not found
			const subnavWidth = Math.ceil(this.element.find('.subnavigation__navigation').width());
			const listWidth = ~~Math.ceil(this.element.find('.subnavigation__list').width());
			const captionWidth = ~~Math.ceil(this.element.find('.subnavigation__caption').outerWidth(true));
			const btnWidth = ~~Math.ceil(this.element.find('.subnavigation__navigation .btn').outerWidth(true));

			const emptyRowWidth = subnavWidth - btnWidth - captionWidth;
			if (emptyRowWidth > listWidth) {
				this.element[0].classList.add('subnavigation--top');
			} else {
				// navigation items are shown on new line
				this.element.find('.subnavigation__caption').css('width', '100%');
				this.element[0].classList.remove('subnavigation--top');
			}
		},

		_onBreakpointChanged() {
			const _this = this;

			// intialize global variable _this.flyout based on current Breakpoint
			const breakpoint = ui.lib.getBreakpoint();
			_this._setFlyout(breakpoint);

			ui.sub('ui.windowresize', () => {
				// on window resize close all Flyouts
				_this._hideFlyouts();
				// on window resize recalculate item position
				_this._setItemPosition();
			});

			// on Breakpoint change, change to mobile or desktop view by setting _this.flyout globally
			ui.sub('ui.breakpointChanged', (e, data) => {
				_this._setFlyout(data);
			});
		},

		_setFlyout(data) {
			if (data === 'lg') {
				this.flyout = '.subnavigation-flyout__desktop#subnavigation-flyout--';
			} else {
				this.flyout = '.subnavigation-flyout__mobile#subnavigation-flyout--';
			}
		},

		_startDragging(event) {
			// enable scrolling
			this.mouseDown = true;

			// set new x-pos of the page on click
			this.startX = event.pageX - this.subnavLinklist.offsetLeft;

			// set global scrollLeft value to prior saved scrollLeft value of the navbar
			this.scrollLeft = this.subnavLinklist.scrollLeft;
		},

		_stopDragging() {
			// prevents scrolling
			this.mouseDown = false;
		},

		_moving(event) {
			event.preventDefault();

			// only if startDragging has been called
			if (!this.mouseDown) {
				return;
			}

			// x-pos on the page
			const x = event.pageX - this.subnavLinklist.offsetLeft;

			// amount of pixels scrolled
			const scroll = x - this.startX;

			// set new scrollLeft value to navbar
			this.subnavLinklist.scrollLeft = this.scrollLeft - scroll;
		}
	});
}(ui.$));
